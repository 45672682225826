import { useEffect, useState, useCallback } from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import { TfiClose } from "react-icons/tfi";
import Confetti from "react-confetti";
import styles from "./popup-campaign.module.css";

const CAMPAIGN_IMAGE =
  "https://res.cloudinary.com/aurumlab/image/upload/v1738636587/aurumlab/banner/popup_campaign/Aurum_Lab_-_Birthday_-_Square_xspjwt.gif";
const INTERACTION_IMAGE = [
  "https://res.cloudinary.com/aurumlab/image/upload/v1738547149/aurumlab/banner/popup_campaign/box-gift-1_fr3jxp.gif",
  "https://res.cloudinary.com/aurumlab/image/upload/v1738547150/aurumlab/banner/popup_campaign/box-gift-2_ia1hls.gif",
];
const CURSOR =
  "https://res.cloudinary.com/aurumlab/image/upload/v1738547154/aurumlab/banner/popup_campaign/Hand_cursor_ungu_mcua9h.png";

const CAMPAIGN_URL = "/all-jewelries";
const COOKIE_NAME = "showPopupCampaign";
const COOKIE_EXPIRATION = 60 * 60 * 24; // 1 day in seconds

const PopupCampaign = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [isScaling, setIsScaling] = useState(false);
  const [disableAnimation, setDisableAnimation] = useState(false);
  const [hasExploded, setHasExploded] = useState(false);
  const [isHalfway, setIsHalfway] = useState(false);
  const [closeIcon, setCloseIcon] = useState(false);

  const [interactionImage, setInteractionImage] = useState(
    INTERACTION_IMAGE[0]
  );
  const router = useRouter();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const hasPopupCookie = document.cookie.includes(`${COOKIE_NAME}=false`);
      if (!hasPopupCookie) setShowPopup(true);
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, []);

  const hidePopup = useCallback(() => {
    document.cookie = `${COOKIE_NAME}=false; Max-Age=${COOKIE_EXPIRATION}`;
    setShowPopup(false);
  }, []);

  const gotoCampaign = useCallback(() => {
    hidePopup();
    if (CAMPAIGN_URL) router.push(CAMPAIGN_URL);
  }, [hidePopup, router]);

  const explodeCampaign = useCallback(() => {
    if (isScaling || hasExploded) return;

    setDisableAnimation(true);
    setIsScaling(true);

    setTimeout(() => {
      setIsScaling(false);
    }, 300);

    setTimeout(() => {
      setInteractionImage(INTERACTION_IMAGE[1]);
      setHasExploded(true);
    }, 0);

    setTimeout(() => {
      setIsHalfway(true);
      setInteractionImage(CAMPAIGN_IMAGE);
    }, 3000);

    setTimeout(() => {
      setCloseIcon(true);
    }, 3200);
  }, [isScaling, hasExploded]);

  if (!showPopup) return null;

  return (
    <div className={styles.overlay}>
      {/* <div className={styles.popup}>
        <Image
          src={CAMPAIGN_IMAGE}
          width={1080}
          height={1080}
          quality={60}
          className={styles.campaign_img}
          alt="popup campaign"
          onClick={gotoCampaign}
          style={{ objectFit: "cover", cursor: "pointer" }}
        />        
        <div className={styles.close_icon_popup} onClick={hidePopup}>
          <TfiClose />
        </div>
      </div> */}
      {hasExploded && (
        <Confetti
          width={900}
          height={1000}
          recycle={false}
          numberOfPieces={500}
          style={{
            marginTop: "100px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          colors={[
            "#212F16",
            "#E2CB59",
            "#BE7B2A",
            "#B9372A",
            "#CBC1CC",
            "#8F78A6",
            "#4D3362",
            "#230B0B",
          ]}
        />
      )}
      <div className={styles.popup_interaction}>
        <div id="interaction-area">
          <Image
            src={interactionImage}
            width={2000}
            height={2000}
            quality={90}
            className={`${styles.interaction_image} ${
              disableAnimation ? "" : styles.shaking
            } ${isScaling ? styles.scaling : ""} `}
            alt="popup campaign"
            onClick={
              interactionImage === CAMPAIGN_IMAGE
                ? gotoCampaign
                : explodeCampaign
            }
            style={{
              objectFit: "cover",
              cursor: "pointer",
            }}
          />
        </div>

        {!hasExploded && (
          <Image
            src={CURSOR}
            width={50}
            height={50}
            alt="cursor"
            className={`${styles.handy}`}
          />
        )}

        {closeIcon && (
          <div className={styles.close_icon_interaction} onClick={hidePopup}>
            <TfiClose />
          </div>
        )}
      </div>
    </div>
  );
};

export default PopupCampaign;
